<template>
  <NuxtLink
    :to="link"
    class="search-result"
    role="option"
    tabindex="-1"
    @click="onLinkClick"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="copy" />
  </NuxtLink>
</template>

<script setup>
  const emit = defineEmits(["resultClick"])

  defineProps({
    link: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
  })

  function onLinkClick() {
    emit("resultClick")
  }
</script>

<style lang="scss">
  .search-result {
    display: block;
    width: auto;
    margin-bottom: 6px;

    span {
      em {
        background-color: $yellow-100;
        font-style: normal;
      }
    }
  }
</style>
