<template>
  <li class="submenu-children">
    <NuxtLink
      :external="isExternal"
      :to="content.linkUrl"
      class="submenu-title"
      @click="resetNav"
    >
      {{ content.name }}
    </NuxtLink>
    <ul v-if="!content.linkUrl && content.children" class="grandchildren">
      <li
        v-for="grandChild in content.children"
        :key="grandChild.id"
        class="grandChild"
      >
        <NuxtLink
          :to="grandChild.linkUrl"
          class="grandChild-link"
          @click="resetNav"
        >
          {{ grandChild.name }}
        </NuxtLink>
      </li>
    </ul>
  </li>
</template>

<script>
  import { mapActions } from "vuex"

  export default {
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    computed: {
      isExternal() {
        return this.content.linkUrl?.startsWith("http")
      },
    },
    methods: {
      ...mapActions("nav", ["resetNav"]),
    },
  }
</script>

<style lang="scss" scoped>
  .submenu-title {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    padding: $base-spacing * 3 $gutter;
    font-weight: bold;
    text-decoration: none;
    color: inherit;
    &:hover {
      background-color: $silver;
    }
    &:focus {
      color: $red;
      outline: none;
      background-color: $silver;
    }
  }
  .submenu-children {
    outline: none;
  }
  .grandchildren {
    padding: 0 $base-spacing * 8 $base-spacing * 3;

    list-style-type: none;
  }
  .grandChild-link {
    @apply text-sm leading-normal;
    color: $gray-600;
    display: inline-block;
    padding: $base-spacing * 2 0;
    color: inherit;
    text-decoration: none;
  }
</style>
