<template>
  <nav class="navigation">
    <ul class="navigation_items">
      <template v-for="navTab in navElements" :key="navTab.id">
        <li
          v-if="getValue(navTab, 'device') != 'mobile'"
          @mouseenter="showTab(navTab.id, navTab.nestedElements.length)"
          @mouseleave="hideTab()"
        >
          <span class="nav-item">
            <nuxt-link
              v-if="getValue(navTab, 'link')"
              class="nav-link"
              :to="getValue(navTab, 'link')"
            >
              {{ getValue(navTab, "title") }}
            </nuxt-link>
            <span v-else class="nav-link">
              {{ getValue(navTab, "title") }}
            </span>
            <span
              v-if="handleTag(getValue(navTab, 'tag'))"
              class="nav-item-tag"
            >
              {{ getValue(navTab, "tag") }}</span
            >
            <NavToggle
              v-show="navTab.nestedElements.length > 0"
              :title="getValue(navTab, 'title')"
              :active="activeNavItem === navTab.id"
              @click="toggle(navTab.id)"
              @keyup.up="navToggleKeyUp(navTab.id)"
            />
          </span>

          <div
            v-show="activeNavItem === navTab.id"
            ref="menu"
            class="navtab-con"
            aria-haspopup="true"
          >
            <NavMenu
              :alchemy-element="navTab"
              :aria-expanded="activeNavItem === navTab.id"
            />
          </div>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script setup lang="ts">
  import NavMenu from "./NavMenu.vue"
  import NavToggle from "./NavToggle.vue"

  const { getValue } = useAlchemy()
  const route = useRoute()

  const activeNavItem = ref<string>("")
  const justNavigated = ref<boolean>(false)
  const menu = ref<any>()

  let addTimer: ReturnType<typeof setTimeout>
  let leaveTimer: ReturnType<typeof setTimeout>

  defineProps<{
    navElements: AlchemyMainMenuItem[]
  }>()

  // Instant reset of activeNavItem
  const reset = () => {
    activeNavItem.value = ""
  }

  // Show Nav tab with delay
  const showTab = (id: string, childCount: number) => {
    if (childCount > 0) {
      clearTimeout(leaveTimer)

      if (activeNavItem.value === id) {
        return
      }
      addTimer = setTimeout(() => {
        activeNavItem.value = id
      }, 150)
    }
  }

  // Hide Nav tab with delay
  const hideTab = () => {
    leaveTimer = setTimeout(() => {
      activeNavItem.value = ""
    }, 350)
    clearTimeout(addTimer)
  }

  // Toggle Nav tab
  const toggle = (id: string) => {
    if (activeNavItem.value === id) {
      reset()
    } else {
      activeNavItem.value = id
    }
  }

  // Reset Nav tab on route change
  watch(
    () => route.fullPath,
    () => {
      if (activeNavItem.value) {
        reset()
        justNavigated.value = true
        setTimeout(() => {
          justNavigated.value = false
        }, 500)
      }
    },
  )

  // Accessibility Keyup on toggle button utility
  const navToggleKeyUp = (id: string) => {
    activeNavItem.value = id
    nextTick(() => {
      const allLinks = menu.value[0].querySelectorAll("a")
      const focusElement = allLinks?.[allLinks.length - 1]
      if (focusElement) {
        focusElement.focus()
      }
    })
  }

  const handleTag = (tag: string | undefined) => {
    if (tag === "none" || tag === null) {
      return false
    }
    return true
  }
</script>

<style lang="scss" scoped>
  .navigation {
    align-items: center;
    display: flex;
  }
  .navigation_items {
    @apply text-lg leading-normal;
    margin: 0;
    padding: 0;
    color: $onyx;
    display: flex;
    list-style-type: none;
  }

  .nav-item {
    cursor: pointer;
    @apply text-md leading-normal;
    font-weight: bold;
    display: flex;
    grid-gap: $base-spacing;
    align-items: center;
    position: relative;
    padding-right: $base-spacing * 6;
    &:hover {
      ~ .menu {
        z-index: 4;
      }
    }
  }

  .nav-item-tag {
    display: inline-block;
    transform: translateY(-1px);
    background: $green-text;
    color: $green-tint;
    padding: 0 $base-spacing * 1.5;
    border-radius: 100px;
    @apply text-xs leading-normal;
    font-family: $font-family-default;
  }

  .nav-link {
    display: inline-block;
    padding: $base-spacing * 6 0;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $onyx;
    }
    &:focus {
      text-decoration: underline;
      color: $onyx;
      display: block;
    }
  }
</style>
