<template>
  <transition name="count-anim">
    <div
      v-if="total > 0"
      class="count"
      :class="[
        updated && 'count-updated',
        `count-${type}`,
        hide && 'count-hide',
      ]"
    >
      {{ total }}
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      total: {
        type: Number,
        default: 0,
      },
      type: {
        type: String,
        default: "cart",
      },
      flash: {
        type: Boolean,
        default: false,
      },
    },
    fetchKey: "cart-icon",
    data() {
      return {
        updated: false,
        hide: false,
      }
    },
    watch: {
      total() {
        this.updated = true
        setTimeout(() => {
          this.updated = false
        }, 250)
      },
    },
    mounted() {
      if (this.flash) {
        setTimeout(() => {
          this.hide = true
        }, 3000)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .count {
    position: absolute;
    top: $base-spacing * 2;
    right: $base-spacing * 2;
    transition: all 220ms ease-in-out;
    @apply text-xs;
    text-align: center;
    font-weight: bold;
    line-height: $base-spacing * 6;
    min-width: $base-spacing * 6;
    display: block;
    font-family: $font-family-default;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;

    border-radius: 100px;
    padding: 0 5px;
    will-change: transform;
    box-sizing: border-box;
    backface-visibility: hidden;
    transform: translate(50%, -50%) scale(1);
    &-cart {
      @apply bg-blue-text text-white;
    }
    &-rewards {
      @apply bg-rewards-400 text-black;
    }
    &-updated {
      transform: translate(50%, -50%) scale(1.25);
    }
    &-hide {
      transform: translate(50%, -50%) scale(0);
      opacity: 0;
    }
  }

  .count-anim {
    &-enter-active {
      transition:
        transform 170ms cubic-bezier(0, 0.88, 0.43, 1.68),
        opacity 170ms ease-in-out;
    }
    &-leave-active {
      transition:
        transform 170ms cubic-bezier(0.86, 0.21, 0.91, 0.62),
        opacity 170ms ease-in-out;
    }
    &-enter,
    &-leave-to {
      transform: scale(0.5);
      opacity: 0;
    }
  }
</style>
