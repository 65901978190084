<template>
  <span class="font-mono whitespace-nowrap">
    {{ countDown }}
  </span>
</template>

<script setup lang="ts">
  const SECOND = 1000
  const MINUTE = SECOND * 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24

  const saleStore = useSaleStore()
  const sale = computed(() => saleStore.activeSale)

  function getDistanceToDate(datestring: string) {
    const date = new Date(datestring).getTime()
    const now = new Date().getTime()
    return date - now
  }

  function leadingZeros(num: number, size: number): string {
    let numStr = num.toString()
    if (numStr.length < size) {
      numStr = "0" + numStr
    }
    return numStr
  }

  const countDown = ref("")

  onMounted(() => {
    if (sale.value) {
      const endDate = new Date(sale.value.endsAt).getTime()

      const runCountdown = setInterval(() => {
        const now = new Date().getTime()
        const distance = endDate - now
        const days = Math.floor(distance / DAY)
        const hours = Math.floor((distance % DAY) / HOUR)
        const minutes = leadingZeros(Math.floor((distance % HOUR) / MINUTE), 2)
        const seconds = leadingZeros(
          Math.floor((distance % MINUTE) / SECOND),
          2,
        )

        countDown.value = `${days ? days + "d" : ""} ${hours}h ${minutes}m ${seconds}s`
      }, 1000)

      setTimeout(() => {
        clearInterval(runCountdown)
      }, getDistanceToDate(sale.value.endsAt))
    }
  })
</script>

<style scoped></style>
