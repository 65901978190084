<template>
  <div
    v-if="announcement"
    class="announcer absolute -h-[1px] -w-[1px] -m[1px] hidden"
    role="log"
    :aria-live="announcementOptions.live || 'assertive'"
    aria-busy="false"
  >
    {{ announcement }}
  </div>
</template>

<script setup>
  import { useNotificationStore } from "~/pinia/notifications"

  const store = useNotificationStore()
  const announcement = computed(() => store.announcement)
  const announcementOptions = computed(() => store.announcementOptions)
</script>

<style lang="postcss" scoped>
  .announcer {
    clip: rect(0px, 0px, 0px, 0px);
  }
</style>
