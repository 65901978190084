<template>
  <nuxt-link
    to="/cart/"
    class="cart-badge"
    :aria-label="
      itemCount === 0
        ? 'Your empty shopping cart'
        : `Your shopping Cart, you have ${itemCount} items in your Cart`
    "
  >
    <SvgIcon class="shopping-cart-icon" icon="shopping-cart-alt" />
    <CountBadge :total="itemCount" />
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      itemCount: {
        type: Number,
        default: 0,
      },
    },
    fetchKey: "cart-icon",
  }
</script>

<style lang="scss" scoped>
  .cart-badge {
    display: flex;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    border: none;
    .shopping-cart-icon {
      display: block;
      fill: none;
      color: $onyx;
      width: $base-spacing * 6;
      height: $base-spacing * 6;
      transition: stroke 170ms ease-in-out;
    }
    &:hover {
      svg {
        color: $blue;
      }
    }
  }
</style>
