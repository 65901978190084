<template>
  <div :class="['content', hasChildren && 'hasChildren']">
    <ul
      :key="subMenu?.node?.id"
      :class="['subtree', `subtree-${subMenu?.role}`]"
    >
      <NuxtLink
        :to="subMenu?.node?.linkUrl"
        class="subtree-title"
        :prefetch="false"
      >
        {{ subMenu?.node?.name }}
        <span v-show="subMenu?.node?.linkUrl" aria-hidden="true">
          <SvgIcon icon="chevron-right" class="chevron-icon" />
        </span>
      </NuxtLink>

      <li
        v-for="navsubtreechild in subMenu?.node?.children"
        :key="navsubtreechild.id"
        class="submenu-children"
      >
        <NuxtLink
          :to="navsubtreechild.linkUrl"
          class="navchild"
          :prefetch="false"
        >
          {{ navsubtreechild.name }}
        </NuxtLink>
        <ul v-show="navsubtreechild.children" class="grandchildren">
          <li
            v-for="grandChild in navsubtreechild.children"
            :key="grandChild.id"
          >
            <NuxtLink
              :to="grandChild.linkUrl"
              class="grandChild-link"
              :prefetch="false"
            >
              {{ grandChild.name }}
            </NuxtLink>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps<{ element: AlchemyMainMenuSubmenu }>()

  const { getIngredient } = useAlchemy()

  const subMenu = getIngredient<AlchemyIngredientNode>(props.element, "submenu")
  const hasChildren =
    subMenu && subMenu.node?.children && subMenu.node?.children.length > 0
</script>

<style lang="scss" scoped>
  .content {
    width: 100%;
    &.hasChildren {
      margin-top: $base-spacing * 4;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  .subtree {
    padding: 0;
    list-style-type: none;
    padding-bottom: $base-spacing * 6;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .subtree-title {
    color: $onyx;
    white-space: nowrap;
    @apply h6;
    display: inline-block;
    .hasChildren & {
      margin-bottom: $base-spacing * 2;
    }
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .subtree-thumbnail {
    display: none;
  }
  .grandchildren {
    color: $gray-400;

    padding-left: $base-spacing * 2.5;
    list-style-type: none;
  }
  .grandChild-link {
    color: $gray-text;
    display: inline-block;
    padding: 2px 0;
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .navchild {
    color: $gray-700;
    padding: 2px 0;
    display: inline-block;
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .chevron-icon {
    pointer-events: none;
    position: relative;
    top: -1px;
    vertical-align: middle;
    display: inline-block;
    width: 12px;
    height: 12px;
  }
</style>
