<template>
  <div
    :id="`top-nav-search-item-${resultIndex}`"
    role="option"
    :aria-selected="focused"
    :class="['result-item', focused && 'active']"
  >
    <NuxtLink
      class="dropdown-result"
      :to="permalink"
      tabindex="-1"
      :prefetch="false"
      @click="$emit('resultClick', $event)"
    >
      <picture class="image">
        <source
          v-for="imgType in createFormatArray(imageSrcSet)"
          :key="imgType.type"
          :type="imgType.type"
          sizes="36px"
          :srcset="imgType.srcsetstring"
        />
        <img
          :src="imagePath || fallbackImage"
          :alt="name"
          aria-hidden="true"
          loading="lazy"
          @error="setFallBackImg"
        />
      </picture>
      <div class="text">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="title" v-html="highlightedName" />
        <span v-if="oilType" class="oil-type">
          {{ oilType }}
        </span>
        <span v-if="pageType === 'fragrance_note'" class="fragrance-note-hint">
          Fragrance Note
        </span>
        <div
          v-if="minPrice && minPrice === maxPrice"
          class="price"
          :aria-label="priceLabel"
        >
          ${{ formatMinPrice }}
        </div>
        <div
          v-else-if="minPrice && maxPrice"
          class="price"
          :aria-label="priceRange"
        >
          ${{ formatMinPrice }} - ${{ formatMaxPrice }}
        </div>
        <div
          v-else-if="minPrice && !maxPrice"
          class="price"
          :aria-label="priceLabel"
        >
          ${{ formatMinPrice }}
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script>
  import searchResultMixin from "./searchResultMixin"
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    mixins: [searchResultMixin, createFormatArray],
    props: {
      focused: {
        type: Boolean,
        default: false,
      },
      resultIndex: {
        type: Number,
        default: 0,
      },
    },
    emits: ["resultClick"],
  }
</script>

<style lang="scss" scoped>
  .result-item {
    &:hover,
    &:focus,
    &.active {
      background-color: $gray-100;
      border-radius: 0px;
      & > .image {
        box-shadow: $hover-shadow;
      }
    }
  }
  .dropdown-result {
    display: flex;
    align-items: center;
    padding: $base-spacing * 3 $base-spacing * 3;
    text-decoration: none;
    width: auto;
    padding: $base-spacing * 2 $base-spacing * 3;

    @include viewport("mini") {
      flex-wrap: wrap;
    }
  }

  .image {
    width: $base-spacing * 9;
    height: $base-spacing * 9;
    margin: $base-spacing $base-spacing * 5 $base-spacing 0px;
    border-radius: $border-radius;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    flex: 1;
  }
  .title {
    color: $onyx;
    text-decoration: none;
    @apply text-sm leading-normal;
  }

  .price {
    color: $gray-700;

    padding-left: 0px;
    @apply text-xs leading-normal;
    @include viewport("mini") {
      padding-left: 0px;
    }
  }
  .text {
    display: block;
    align-self: center;
  }

  .oil-type,
  .fragrance-note-hint {
    @apply text-sm leading-normal;
    color: $gray-text;
    margin-left: 1ex;
  }
</style>
