const URLS_TO_PRECONNECT = [
  [`${import.meta.env.VITE_API_URL}`],
  ["https://www.googletagmanager.com"],
  ["https://static.klaviyo.com"],
  ["https://www.googleadservices.com"],
  ["https://www.google-analytics.com"],
  ["https://fonts.googleapis.com"],
  ["https://fonts.gstatic.com", { crossorigin: true }],
  ["https://fast.wistia.com"],
  ["https://cdn.curalate.com"],
  ["https://connect.facebook.net"],
  ["https://analytics.tiktok.com"],
]
export const PRECONNECT_LIST = URLS_TO_PRECONNECT.map((conf) => ({
  rel: "preconnect",
  href: conf[0],
  ...conf[1],
}))
