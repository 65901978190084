<template>
  <div v-if="mobileNavIsOpen" class="mobile-nav">
    <ul class="list">
      <template v-for="item in navElements" :key="item.id">
        <MobileNavItem
          v-show="!selectedItem || item.id == selectedItem"
          :element="item"
          :selected="item.id === selectedItem"
          @itemClick="selectNavItem(item.id)"
        />
      </template>
    </ul>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex"
  import MobileNavItem from "./MobileNavItem"

  export default {
    components: { MobileNavItem },
    props: {
      navElements: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
    data() {
      return {
        hideNav: false,
      }
    },
    computed: {
      ...mapState("nav", {
        selectedItem: (state) => state.selectedItem,
        mobileNavIsOpen: (state) => state.mobileNavIsOpen,
      }),
    },
    methods: {
      ...mapActions("nav", ["selectNavItem"]),
      navItemClick(id) {
        window.scroll(0, 0)
        this.selectNavItem(id)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mobile-nav {
    background: white;
    padding: 0;
    margin: 0 $gutter-negative;
    @apply text-md leading-normal;
  }
  .session-link {
    padding: $base-spacing * 2 $base-spacing * 4;
  }
  .search-wrap {
    padding: 0 $base-spacing * 4;
    position: relative;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
    @include viewport("sm") {
      padding: 0 $base-spacing * 6 0 $base-spacing * 8;
    }
  }

  .mobile-search {
    width: 100%;
  }

  .list {
    display: block;
    padding: 0;
    margin: 0;
    @include viewport("sm") {
      padding: $base-spacing * 6 0;
    }
    @include viewport("mini") {
      padding: $base-spacing * 2 0;
    }
  }

  .mobile-nav-footer {
    height: $base-spacing * 20;
    padding: $base-spacing * 4 $base-spacing * 4;
    font-weight: bold;
    background: $gray-200;
  }
</style>
