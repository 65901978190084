<template>
  <div class="display-flex items-center justify-center p-3.5">
    <svg
      ref="svgSpinner"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      class="circular-progress"
    >
      <circle class="fg" />
    </svg>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      duration: number
    }>(),
    {
      duration: 1000,
    },
  )

  const pauseAnimation = () => {
    if (animation.value) {
      animation.value.pause()
    }
  }
  const playAnimation = () => {
    if (animation.value) {
      animation.value.play()
    } else {
      animation.value = svgSpinner.value?.animate(
        { "--progress": [0, 100] },
        {
          duration: props.duration,
          iterations: Infinity,
          easing: "linear",
        },
      )
    }
  }

  defineExpose({
    pauseAnimation,
    playAnimation,
  })

  const animation: Ref<Animation | undefined> = ref(undefined)
  const svgSpinner = ref<SVGElement | null>(null)
</script>

<style scoped>
  .circular-progress {
    --size: 24px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 2px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    display: block;
  }

  .circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
  }

  .circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    stroke: currentColor;
  }

  @property --progress {
    syntax: "<number>";
    inherits: false;
    initial-value: 0;
  }

  @keyframes progress-animation {
    from {
      --progress: 0;
    }
    to {
      --progress: 100;
    }
  }
</style>
