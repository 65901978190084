<template>
  <Container
    class="notification-area"
    :vertical-padding="false"
    :horizontal-padding="horizontalPadding"
  >
    <Alert
      v-for="(notification, i) in notifications"
      :key="i"
      :type="notification.type"
      :dismissible="true"
    >
      {{ notification.message }}
    </Alert>
  </Container>
</template>

<script lang="ts" setup>
  import { useNotificationStore } from "~/pinia/notifications"
  const notificationStore = useNotificationStore()

  defineProps({
    horizontalPadding: {
      type: Boolean,
      default: true,
    },
  })

  const notifications = computed(() => notificationStore.notifications)
</script>
