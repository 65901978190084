<template>
  <img
    class="h-[44px] w-[220px] hidden sm:block max-w-full"
    :src="LOGO_LG"
    alt="Candle making supplies from CandleScience"
  />
  <img
    class="h-[44px] w-[160px] block sm:hidden max-w-full"
    :src="LOGO_SM"
    alt="Candle making supplies from CandleScience"
  />
</template>

<script setup>
  // const LOGO_LG = "/logo-with-text.svg"
  // const LOGO_SM = "/logotype.svg"

  const LOGO_LG = "/logo-20-lg.avif"
  const LOGO_SM = "/logo-20-sm.avif"

  defineProps({
    text: {
      type: Boolean,
      default: false,
    },
  })
</script>
<script>
  export default {
    name: "CsLogo",
  }
</script>
