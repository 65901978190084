<template>
  <div class="subtree-wrap">
    <Item
      :selected="selected"
      :title="title"
      :href="href"
      :image="thumbnail"
      :image-alt="thumbnailAlt"
      :v-if="hasChildren"
      :title-only="!hasChildren"
      @click="navToggle"
      @backButtonClick="$emit('subtreeBack')"
    />

    <template v-for="subtree in sortedContent">
      <ul
        v-if="subtree.role === 'submenu' && selected"
        :key="subtree.id"
        :class="['subtree', `subtree-${subtree.role}`]"
      >
        <template v-for="child in subtree.node.children">
          <MobileNavSubtreeChild
            v-if="selected"
            :key="child.id"
            :content="child"
          />
        </template>
      </ul>
    </template>
  </div>
</template>

<script>
  import Item from "./Item"
  import MobileNavSubtreeChild from "./MobileNavSubtreeChild"
  import { AlchemyElement } from "~/mixins/alchemyVue"
  export default {
    components: {
      MobileNavSubtreeChild,
      Item,
    },
    mixins: [AlchemyElement],
    props: {
      selected: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      const submenuNode = this.getIngredient("submenu").node
      const thumbnail = this.getIngredient("thumbnail")
      return {
        thumbnail: thumbnail.value,
        thumbnailAlt: thumbnail.altText,
        title: submenuNode.name,
        href: submenuNode.linkUrl,
      }
    },
    computed: {
      sortedContent() {
        return [...this.element.ingredients].sort((st) => {
          return st.role === "thumbnail" ? -1 : 1
        })
      },
      hasChildren() {
        let hasChildren = false
        this.element.ingredients.forEach((i) => {
          if (i.node && i.node.children && i.node.children.length > 0) {
            hasChildren = true
          }
        })
        return hasChildren
      },
    },

    methods: {
      navToggle() {
        this.$emit("selected", !this.selected)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .thumbnail {
    width: $base-spacing * 12;
    height: $base-spacing * 12;
    border-radius: $border-radius;
    box-shadow: $shadow;
    background: center;
    margin-right: $base-spacing * 4;
  }
  .subtree-wrap {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    &:focus {
      outline: none;
    }
  }

  .subtree-title {
    padding: $base-spacing * 3 $base-spacing * 8;

    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover {
      background-color: $silver;
      .image {
        box-shadow: $hover-shadow;
      }
    }
    &.isBackButton {
      color: $red;
    }
  }

  .subtree {
    list-style-type: none;
    padding: 0;
  }
</style>
